import '../scss/index.scss';
// import 'bootstrap';
import 'swiper';

require('magnific-popup');
require('simplebar');

import 'cookieconsent';
// import AOS from 'aos';

import { library, dom } from '@fortawesome/fontawesome-svg-core';
import { faFacebookF } from '@fortawesome/free-brands-svg-icons/faFacebookF';
import { faTwitter } from '@fortawesome/free-brands-svg-icons/faTwitter';
import { faInstagram } from '@fortawesome/free-brands-svg-icons/faInstagram';
import { faLinkedinIn } from '@fortawesome/free-brands-svg-icons/faLinkedinIn';
import { faBehance } from '@fortawesome/free-brands-svg-icons/faBehance';
import { faChevronRight } from '@fortawesome/free-solid-svg-icons/faChevronRight';
import SwiperCore, { Lazy, Navigation, Pagination, Autoplay, EffectCards } from 'swiper';
import Swiper from 'swiper';
import gsap from 'gsap';
import fullpage from 'fullpage.js';
import SimpleBar from 'simplebar';

SwiperCore.use([Navigation, Lazy, Pagination, Autoplay, EffectCards ]);

library.add(faFacebookF);
library.add(faTwitter);
library.add(faInstagram);
library.add(faLinkedinIn);
library.add(faBehance);
library.add(faChevronRight);
dom.watch();

const endpoint = 'https://madwise.si/blog/wp-json/wp/v2/';
const ajaxEndpoint = 'https://madwise.si/blog/wp-admin/admin-ajax.php';

$(document).ready(function () {
    'use strict';
    
    jQuery.ajaxSetup({ cache: false });

    //skriptica za odpiranje footer dropdown menijev na mobile - ni najleps, ampak deluje
    function isTouchDevice() {
      return 'ontouchstart' in window        // works on most browsers 
          || navigator.maxTouchPoints;       // works on IE10/11 and Surface
    };
    

    //ob touchu na dropdown gumb naj se zaprejo vsi odprti dropdowni in naj se odpre samo kliknjen
    if(isTouchDevice()){
        $(".footer-menu > li.nav-item-dropdown").each(function(){
            $(this).children(".dropdown-menu").addClass("submenu-dropdown");
            $(this).children(".dropdown-menu").removeClass("dropdown-menu");
            
        });

        $(".footer-menu .dropdown-puscica").click(function(e){
            e.preventDefault();
            e.stopPropagation();
            
            if ($(this).parent().hasClass("active")) {
                $(this).parent().removeClass("active");
            }
            else {
                $('.footer-menu > li.nav-item-dropdown').each(function(){
                    $(this).removeClass("active");
                });  
                $(this).parent().addClass("active");
            }
        });
    }
    
    // Smooth scrolling using jQuery easing
    /*
    $('a.js-scroll-trigger[href*="#"]:not([href="#"]), .js-scroll-trigger a[href*="#"]:not([href="#"])').click(function () {
        if (location.pathname.replace(/^\//, '') == this.pathname.replace(/^\//, '') && location.hostname == this.hostname) {
            var target = $(this.hash);
            target = target.length ? target : $('[name=' + this.hash.slice(1) + ']');
            if (target.length) {
                $('html, body').animate({
                    scrollTop: (target.offset().top - 80)
                }, 400, "easeInOutExpo");
                return false;
            }
        }
    });
    */

    getSiteCookieData(function(response) {
        if (response.length !== 0) {
            $('#cookie-data').html(response[0].content.rendered);
            $('#site-name').html('(' + response[0].title.rendered + ')');

            $('#cookie-data table').addClass('table');
        } else {
            alert('Zahtevana domena ne obstaja v naši podatkovni bazi.');
        }
    });

    if (!$('.esg-landing').length) {
    // Smooth scrolling using jQuery easing
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 80
            }, 400);
        });
    }
    

    if ($(".form-control").length) {
        $(".form-control").blur(function () {
            if ($(this).val()) {
                $(this).addClass("filled");
            }
        });

        $(".form-control").focus(function () {
            if ($(this).hasClass('filled')) {
                $(this).removeClass("filled");
            }
        });
    }

    var current = location.pathname;
    //stripping the /madwise-website/ from URL - dev environment
    if (location.hostname === "localhost" || location.hostname === "127.0.0.1") {
        current = current.replace("/madwise-website/", "");
    }
    current = current.replace("/", "");
    if (current != "") {
        $('#nav li > a').each(function () {
            var $this = jQuery(this);
            // if the current path is like this link, make it active
            if ($this.attr('href').indexOf(current) !== -1) {
                $this.addClass('active');
            }
        })
    }

    // Hamburger menu animation
    $(".hamburger").click(function () {
        $(this).toggleClass("is-active");
        $(".navbar-collapse").toggleClass("is-active");
        $("body").toggleClass("navbar-is-active");
    });

    // Collapse Navbar
    let navbarCollapse = function () {
        if ($('#mainNav').offset().top > 150) {
            $('#mainNav').addClass('scrolled');
            $('.top-navigation-row').addClass('top-navigation-scrolled');
        } else {
            $('#mainNav').removeClass('scrolled');
            $('.top-navigation-row').removeClass('top-navigation-scrolled');
        }
    };

    if ($('#mainNav').length) {
        // Collapse now if page is not at top
        navbarCollapse();
        // Collapse the navbar when page is scrolled
        $(window).scroll(navbarCollapse);
    }

    new Swiper('.hero-swiper', {
        slidesPerView: 3,
        loop: true,
        speed: 5000,
        autoplay: {
            delay: 0,
            disableOnInteraction: false,
            pauseOnMouseEnter: false
        },
        allowTouchMove: false,
        breakpoints: {
            768: {
                slidesPerView: 7.5,
                slidesOffsetBefore: 100,
            }
        }
    });

    if (window.matchMedia('(max-width: 992px)').matches) {
        new Swiper('.reference-card-swiper', {
            slidesPerView: 1,
            spaceBetween: 30,
            pagination: {
                el: '.reference-card-swiper .swiper-pagination',
                clickable: true
            }
        });


        new Swiper('.storitve-card-swiper', {
            slidesPerView: 1.2,
            spaceBetween: 30,
            /*effect: 'cards',
            cardsEffect: {
                rotate: false,
                perSlideOffset: 10,
                slideShadows: false
            },*/
            pagination: {
                el: '.storitve-card-swiper .swiper-pagination',
                clickable: true
            }
        });
    }

    // Partners slider carousel
    let partnersCarouselSlider = new Swiper('.partners-carousel-slider .swiper-container', {
        slidesPerView: 1,
        spaceBetween: 30,
        lazy: {
            watchSlidesVisibility: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
        },
        loop: true,
        navigation: {
            nextEl: '.partners-carousel-arrow-next',
            prevEl: '.partners-carousel-arrow-prev'
        },
        pagination: {
            el: '.partners-carousel-slider .swiper-pagination',
            clickable: true
        }
    });

    
    jQuery('.partners-slider .swiper-slide').on('click', function() {
        let partnerIndex = parseInt(jQuery(this).attr('data-slide-index')) + 1;

        if (!Array.isArray(partnersCarouselSlider)) {
            partnersCarouselSlider = [partnersCarouselSlider];
        }

        partnersCarouselSlider.forEach((e) => {
            e.slideTo(partnerIndex);
        })
    });


    /*$('.partners-carousel-slider').slick({
        slidesToShow: 1,
        fade: true,
        slidesToScroll: 1,
        nextArrow: '<div class="slider-next"><i class="icon-arrow" aria-hidden="true"></i></div>',
        prevArrow: '<div class="slider-prev"><i class="icon-arrow" aria-hidden="true"></i></div>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    arrows: false
                }
            }
        ]
    });*/

    let noDesktopScroll = jQuery('.partners-slider').hasClass('no-desktop-scroll') || false;

    new Swiper('.partners-slider .swiper-container', {
        slidesPerView: 2,
        loop: true,
        lazy: {
            watchSlidesVisibility: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
        },
        preloadImages: false,
        navigation: {
            nextEl: '.partners-slider-arrow-next',
            prevEl: '.partners-slider-arrow-prev'
        },
        breakpoints: {
            480: {
                slidesPerView: 4
            },
            767: {
                slidesPerView: 6
            },
            991: {
                slidesPerView: 8,
                allowTouchMove: !noDesktopScroll
            }
        }
    });

    // Partners slider logos
    /*$('.partners-slider').slick({
        slidesToShow: 8,
        slidesToScroll: 1,
        asNavFor: '.partners-carousel-slider',
        focusOnSelect: true,
        nextArrow: '<div class="slider-next"><i class="icon-arrow" aria-hidden="true"></i></div>',
        prevArrow: '<div class="slider-prev"><i class="icon-arrow" aria-hidden="true"></i></div>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });*/

    // On before slide change match active thumbnail to current slide
    $('.partners-carousel-slider').on('beforeChange', function (event, slick, currentSlide, nextSlide) {
        var mySlideNumber = nextSlide;
        $('.partners-slider .slick-slide').removeClass('slick-current');
        $('.partners-slider .slick-slide').eq(mySlideNumber).addClass('slick-current');
    });
    
    new Swiper('.reference-slider .swiper-container', {
        slidesPerView: 1,
        loop: true,
        lazy: {
            watchSlidesVisibility: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
        },
        preloadImages: false,
        navigation: {
            nextEl: '.reference-arrow-next',
            prevEl: '.reference-arrow-prev'
        },
        breakpoints: {
            480: {
                slidesPerView: 2
            },
            767: {
                slidesPerView: 3
            }
        },
    });

    new Swiper('.services-slider .swiper-container', {
        slidesPerView: 1,
        loop: true,
        lazy: {
            watchSlidesVisibility: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
        },
        preloadImages: false,
        allowTouchMove: true,
        navigation: {
            nextEl: '.services-arrow-next',
            prevEl: '.services-arrow-prev'
        },
        breakpoints: {
            575: {
                slidesPerView: 2
            },
            990: {
                slidesPerView: 4,
                allowTouchMove: false
            }
        },
    });

    new Swiper('.footer-slider .swiper-container', {
        slidesPerView: 2,
        loop: true,
        allowTouchMove: true,
        navigation: {
            nextEl: '.footer-slider-next',
            prevEl: '.footer-slider-prev'
        },
        breakpoints: {
            480: {
                slidesPerView: 4
            },
            767: {
                slidesPerView: 6
            },
            991: {
                slidesPerView: 8,
                allowTouchMove: false
            }
        }
    });

    // Footer slider
    /*$(".footer-slider").slick({
        lazyLoad: 'ondemand',
        slidesToShow: 8,
        nextArrow: '<div class="slider-next"><i class="icon-arrow" aria-hidden="true"></i></div>',
        prevArrow: '<div class="slider-prev"><i class="icon-arrow" aria-hidden="true"></i></div>',
        responsive: [
            {
                breakpoint: 991,
                settings: {
                    slidesToShow: 6,
                    slidesToScroll: 1,
                    infinite: true,
                    arrows: true
                }
            },
            {
                breakpoint: 767,
                settings: {
                    slidesToShow: 4
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 2
                }
            }
        ]
    });*/

    // START OF CUSTOM DROPDOWN

    function createCustomDropdowns() {
        $('select:not(#select-service)').each(function (i, select) {
            if (!$(this).next().hasClass('dropdown')) {
                $(this).after('<div class="dropdown ' + ($(this).attr('class') || '') + '" tabindex="0"><span class="current"></span><div class="list"><ul></ul></div></div>');
                var dropdown = $(this).next();
                var options = $(select).find('option');
                var selected = $(this).find('option:selected');
                dropdown.find('.current').html(selected.data('display-text') || selected.text());
                options.each(function (j, o) {
                    var display = $(o).data('display-text') || '';
                    dropdown.find('ul').append('<li class="option ' + ($(o).is(':selected') ? 'selected' : '') + '" data-value="' + $(o).val() + '" data-display-text="' + display + '">' + $(o).text() + '</li>');
                });
            }
        });
    }

    // Event listeners

    // Open/close
    $(document).on('click', '.dropdown', function (event) {
        $('.dropdown').not($(this)).removeClass('open');
        $(this).toggleClass('open');
        if ($(this).hasClass('open')) {
            $(this).find('.option').attr('tabindex', 0);
            $(this).find('.selected').focus();
        } else {
            $(this).find('.option').removeAttr('tabindex');
            $(this).focus();
        }
    });

    // Close when clicking outside
    $(document).on('click', function (event) {
        if ($(event.target).closest('.dropdown').length === 0) {
            $('.dropdown').removeClass('open');
            $('.dropdown .option').removeAttr('tabindex');
        }
        event.stopPropagation();
    });

    // Option click
    $(document).on('click', '.dropdown .option', function (event) {
        $(this).closest('.list').find('.selected').removeClass('selected');
        $(this).addClass('selected');
        var text = $(this).data('display-text') || $(this).text();
        $(this).closest('.dropdown').find('.current').text(text);
        $(this).closest('.dropdown').prev('select').val($(this).data('value')).trigger('change');
    });

    // Keyboard events
    $(document).on('keydown', '.dropdown', function (event) {
        var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
        // Space or Enter
        if (event.keyCode == 32 || event.keyCode == 13) {
            if ($(this).hasClass('open')) {
                focused_option.trigger('click');
            } else {
                $(this).trigger('click');
            }
            return false;
            // Down
        } else if (event.keyCode == 40) {
            if (!$(this).hasClass('open')) {
                $(this).trigger('click');
            } else {
                focused_option.next().focus();
            }
            return false;
            // Up
        } else if (event.keyCode == 38) {
            if (!$(this).hasClass('open')) {
                $(this).trigger('click');
            } else {
                var focused_option = $($(this).find('.list .option:focus')[0] || $(this).find('.list .option.selected')[0]);
                focused_option.prev().focus();
            }
            return false;
            // Esc
        } else if (event.keyCode == 27) {
            if ($(this).hasClass('open')) {
                $(this).trigger('click');
            }
            return false;
        }
    });

    createCustomDropdowns();

    // START OF CUSTOM DROPDOWN

    //$("blockquote").children().wrap('<div class="oval-item"></div>');
    //$("blockquote .oval-item").prepend('<div class="corner-box quote-icon"></div>');

    // Mobile dropdown open/close
    
    $(".mobile-dropdown").click(function () {
        $(".dropdown-menu").hide();

        if ($(this).hasClass("mobile-dropdown-opened")) {
            $(".mobile-dropdown-opened").removeClass("mobile-dropdown-opened");
        } else {
            $(this).addClass("mobile-dropdown-opened");
            $(this).parent().next(".dropdown-menu").show();
        }
    });

    // Select Services Dropdown
    $(".start-project-top").append('<div id="generate-select-services"></div>');
    $("#select-service option").each(function () {
        if($(this).val() != '') {
            $("#generate-select-services").append('<div data-select="' + $(this).val() + '"><span>' + $(this).text() + '</span></div>');
        }
    });
    var serviceValues = [];
    $("#generate-select-services div").click(function () {
        $(this).toggleClass("active");

        if($(this).hasClass("active")) {
            serviceValues.push($(this).data("select"));
        } else {
            var index = serviceValues.indexOf($(this).data("select"));
            if (index > -1) {
                serviceValues.splice(index, 1);
            }
        }

        clearSelected();
        $.each(serviceValues, function(i,e){
            $("#select-service option[value='" + e + "']").prop("selected", true);
        });
    });

    function clearSelected(){
        var elements = document.getElementById("select-service").options;

        for(var i = 0; i < elements.length; i++){
            elements[i].selected = false;
        }
    }

    // Contact form - Start A Project
    jQuery("#submit-start-project").click(function (e) {
        e.preventDefault();
        var errors = [];
        var selectService = jQuery("#select-service");
        var contactName = jQuery("#contact-name");
        var contactEmail = jQuery("#contact-email");
        var contactCompany = jQuery("#contact-company");
        var contactMessage = jQuery("#contact-message");

        var terms = jQuery("#terms");

        jQuery(".form-error").removeClass("form-error");
        jQuery(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();

        //Check if Service Select is selected
/*         if(!checkField(selectService) || selectService.val() == '') {
            selectService.parent().addClass("form-error");
            errors.push("selectService");
        } */

        if(!checkField(contactName)) {
            contactName.parent().addClass("form-error");
            errors.push("contactName");
        }

        if(!checkField(contactEmail)) {
            contactEmail.parent().addClass("form-error");
            errors.push("contactEmail");
        }

        //Validate email
        if(contactEmail.val() != '') {
            if(!validateEmail(contactEmail.val())) {
                contactEmail.parent().addClass("form-error");
                errors.push("contactEmail-validation");
            }
        }

        if(!validateCompany(contactCompany)) {
            contactCompany.parent().addClass("form-error");
            errors.push("contactCompany");
        }

        if(!checkField(contactMessage)) {
            contactMessage.parent().addClass("form-error");
            errors.push("contactMessage");
        }

        //Check if Terms are checked
        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");
            errors.push("terms");
        }

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#form-start-project').serialize();

            var action = 'sendStartAProject';
            data += "&action=" + action;
            data += '&is-english-version=' + isEnglishVersion();

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if(jsonData.status) {
                        selectService.val("");
                        contactName.val("");
                        contactEmail.val("");
                        contactCompany.val("");
                        contactMessage.val("");
                        terms.prop("checked", false);
                        $("#generate-select-services div").removeClass("active");
                        $(".form-control").removeClass("filled");

                        $(".contact-wrapper .contact-inner").hide();
                        $(".contact-wrapper .contact-inner + .form-success-wrapper").show();

                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".contact-wrapper").offset().top - 90
                        }, 500);

                        window.dataLayer = window.dataLayer || [];
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });

        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    // Contact form - Kontakt
    jQuery("#submit-contact-form").click(function (e) {
        e.preventDefault();
        var errors = [];
        var contactName = jQuery("#contact-name");
        var contactEmail = jQuery("#contact-email");
        var contactMessage = jQuery("#contact-message");
        var terms = jQuery("#terms");

        jQuery(".form-error").removeClass("form-error");
        jQuery(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();

        if(!checkField(contactName)) {
            contactName.parent().addClass("form-error");
            errors.push("contactName");
        }

        if(!checkField(contactEmail)) {
            contactEmail.parent().addClass("form-error");
            errors.push("contactEmail");
        }

        //Validate email
        if(contactEmail.val() != '') {
            if(!validateEmail(contactEmail.val())) {
                contactEmail.parent().addClass("form-error");
                errors.push("contactEmail-validation");
            }
        }

        if(!checkField(contactMessage)) {
            contactMessage.parent().addClass("form-error");
            errors.push("contactMessage");
        }

        //Check if Terms are checked
        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");
            errors.push("terms");
        }

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#form-contact-form').serialize();
            var action = 'sendContactForm';
            data += "&action=" + action;
            data += '&is-english-version=' + isEnglishVersion();
           
            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if(jsonData.status) {
                        contactName.val("");
                        contactEmail.val("");
                        contactMessage.val("");
                        terms.prop("checked", false);
                        $(".form-control").removeClass("filled");

                        $(".contact-wrapper .contact-inner").hide();
                        $(".contact-wrapper .contact-inner + .form-success-wrapper").show();

                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".contact-wrapper").offset().top - 90
                        }, 500);

                        window.dataLayer = window.dataLayer || []
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });

        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    // Contact form - Kontakt Part
    $("#submit-contact-form-part").click(function (e) {
        e.preventDefault();
        var errors = [];
        var contactFirstName = $("#contact-firstname");
        var contactLastName = $("#contact-lastname");
        var contactEmail = $("#contact-email");
        var contactCompany = $("#contact-company");
        var contactMessage = $("#contact-message");
        var terms = $("#terms");

        $(".form-error").removeClass("form-error");
        $(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();

        if(!checkField(contactFirstName)) {
            contactFirstName.parent().addClass("form-error");
            errors.push("contactFirstName");
        }

        if(!checkField(contactLastName)) {
            contactLastName.parent().addClass("form-error");
            errors.push("contactLastName");
        }

        if(!checkField(contactEmail)) {
            contactEmail.parent().addClass("form-error");
            errors.push("contactEmail");
        }

        //Validate email
        if(contactEmail.val() != '') {
            if(!validateEmail(contactEmail.val())) {
                contactEmail.parent().addClass("form-error");
                errors.push("contactEmail-validation");
            }
        }

        if(!validateCompany(contactCompany)) {
            contactCompany.parent().addClass("form-error");
            errors.push("contactCompany");
        }

        if(!checkField(contactMessage)) {
            contactMessage.parent().addClass("form-error");
            errors.push("contactMessage");
        }

        //Check if Terms are checked
        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");
            errors.push("terms");
        }

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#form-contact-form-part').serialize();

            var action = 'sendContactFormPart';
            data += "&action=" + action;
            data += "&is-english-version=" + isEnglishVersion();

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if(jsonData.status) {
                        contactFirstName.val("");
                        contactLastName.val("");
                        contactEmail.val("");
                        contactCompany.val("");
                        contactMessage.val("");
                        terms.prop("checked", false);
                        $(".form-control").removeClass("filled");

                        $("#form-contact-form-part").hide();
                        $("#form-contact-form-part + .form-success-wrapper").show();

                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".contact-form").offset().top - 90
                        }, 500);

                        window.dataLayer = window.dataLayer || []
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });

        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    // Contact form - Guide
    jQuery("#submit-guide").click(function (e) {
        e.preventDefault();
        var errors = [];
        var contactEmail = jQuery("#guide-email");
        var contactName = jQuery("#guide-firstname");
        var contactCompany = jQuery("#guide-company");
        var terms = jQuery("#terms");

        jQuery(".form-error").removeClass("form-error");
        jQuery(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();

        if(!checkField(contactEmail)) {
            contactEmail.parent().addClass("form-error");
            errors.push("contactEmail");
        }

        //Validate email
        if(contactEmail.val() != '') {
            if(!validateEmail(contactEmail.val())) {
                contactEmail.parent().addClass("form-error");
                errors.push("contactEmail-validation");
            }
        }

        if(!checkField(contactName)) {
            contactName.parent().addClass("form-error");
            errors.push("contactName");
        }

        if(!validateCompany(contactCompany)) {
            contactCompany.parent().addClass("form-error");
            errors.push("contactCompany");
        }

        //Check if Terms are checked
        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");
            errors.push("terms");
        }

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#form-guide').serialize();

            var action = 'sendContactToAC';
            data += '&action=' + action;
            data += '&is-english-version=' + isEnglishVersion();

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if (jsonData.status === 'invalid-mail') {
                        contactEmail.parent().addClass("form-error");
                        errors.push("contactEmail-validation");

                        return false;
                    }

                    if(jsonData.status) {
                        contactEmail.val("");
                        contactName.val("");
                        contactCompany.val("");
                        terms.prop("checked", false);
                        $(".form-control").removeClass("filled");

                        window.dataLayer = window.dataLayer || []
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });

                        var thank_you_url = $(".thank-you-url").text();
                        //če imamo definiran thank you url, potem preusmeri na thank you page
                        if (thank_you_url) {
                            window.location.href = thank_you_url;
                        }
                        //če nimamo definiranega thank you url-ja, potem prikaži thank you popup
                        else {
                            //Prikaži popup
                            $("#form-guide").hide();
                            $("#form-guide + .form-success-wrapper").show();
                        }
                        

                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });
        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    $('.tracked-audit-banner a').on('click', function() {
        window.dataLayer = window.dataLayer || [];

        dataLayer.push({
            'event': 'onSiteBannerClick',
        });
    });

    $("#submit-newsletter").click(function (e) {
        e.preventDefault();
        var errors = [];
        var contactEmail = jQuery("#newsletter-email");
        var terms = jQuery("#terms");

        jQuery(".form-error").removeClass("form-error");
        jQuery(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();

        if(!checkField(contactEmail)) {
            contactEmail.parent().addClass("form-error");
            errors.push("contactEmail");
        }

        //Validate email
        if(contactEmail.val() != '') {
            if(!validateEmail(contactEmail.val())) {
                contactEmail.parent().addClass("form-error");
                errors.push("contactEmail-validation");
            }
        }

        //Check if Terms are checked
        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");
            errors.push("terms");
        }

        //CHECK IF ALL GOOD
        if(errors.length == 0) {
            var data = jQuery('#newsletter-form').serialize();
            var action = 'sendContactToAC';
            data += "&action=" + action;

            jQuery.ajax({
                type: 'POST',
                dataType : "json",
                url: ajaxEndpoint,
                data: data,
                complete: function (json) {
                    var jsonData = jQuery.parseJSON(json.responseText);

                    if(jsonData.status) {
                        contactEmail.val("");
                        terms.prop("checked", false);
                        $(".form-control").removeClass("filled");

                        //prikaži popup
                        $(".newsletter-wrapper .newsletter-wrapper-inner").hide();
                        $(".newsletter-wrapper .newsletter-wrapper-inner + .form-success-wrapper").show();

                        $([document.documentElement, document.body]).animate({
                            scrollTop: $(".newsletter-wrapper").offset().top - 90
                        }, 500);

                        window.dataLayer = window.dataLayer || []
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                            'obrazec': 'newsletter-prijava'
                        });
                    } else {
                        console.log("FAILED MESSAGE!");
                    }
                }
            });
        } else {
            jQuery(".form-errors-notice").show();
            jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
        }
    });

    jQuery("#gdpr-1").change(function() {
        if(this.checked) {
            jQuery('input:checkbox').not(this).prop('checked', this.checked);
        } else {
            jQuery('input:checkbox').not(this).prop('checked', false);
        }
    });
	
	$("#submit-newsletter-footer").click(function (e) {
		e.preventDefault();
		var errors = [];
		var contactEmail = jQuery("#newsletter-email-footer");
		var terms = jQuery("#terms2");
		
		jQuery(".form-error").removeClass("form-error");
		jQuery(".form-errors-notice, .form-success-notice, .gdpr-error-group").hide();
		
		if(!checkField(contactEmail)) {
			contactEmail.parent().addClass("form-error");
			errors.push("contactEmail");
		}
		
		//Validate email
		if(contactEmail.val() != '') {
			if(!validateEmail(contactEmail.val())) {
				contactEmail.parent().addClass("form-error");
				errors.push("contactEmail-validation");
			}
		}
		
		//Check if Terms are checked
		if(!terms.is(':checked')) {
			terms.parent().addClass("form-error");
			errors.push("terms");
		}
		
		//CHECK IF ALL GOOD
		if(errors.length == 0) {
			var data = jQuery('#newsletter-form-footer').serialize();
			var action = 'salesManagoFooterNewsletter';
			data += "&action=" + action;
			
			jQuery.ajax({
				type: 'POST',
				dataType : "json",
				url: ajaxEndpoint,
				data: data,
				complete: function (json) {
					var jsonData = jQuery.parseJSON(json.responseText);
					
					if(jsonData.status) {
						contactEmail.val("");
						terms.prop("checked", false);
						$(".form-control").removeClass("filled");
						
						//prikaži popup
						$(".newsletter-wrapper-footer .newsletter-wrapper-inner").hide();
						$(".newsletter-wrapper-footer .newsletter-wrapper-inner + .form-success-wrapper").show();
						
                        
						$([document.documentElement, document.body]).animate({
							scrollTop: $(".newsletter-wrapper-footer").offset().top - 90
						}, 500);
						
						window.dataLayer = window.dataLayer || []
						dataLayer.push({
							'event': 'obrazecIzpolnjen',
						});
					} else {
						console.log("FAILED MESSAGE!");
					}
				}
			});
		} else {
			jQuery(".form-errors-notice").show();
			jQuery(".form-errors-notice").text("Preverite, ali ste izpolnili in označili vsa obvezna polja.");
		}
	});

    $('#index-newsletter-form').on('submit', function(e) {
        e.stopPropagation();
        e.preventDefault();

        $('.form-error').removeClass('form-error');

        const errors = [];

        const email = $('input[name="email"]');
        const consent = $('input[name="acceptance"]');

        if (!validateEmail(email.val())) {
            $(this).addClass('form-error');

            errors.push(1);
        }

        if (!consent.is(':checked')) {
            consent.parent().addClass('form-error');

            errors.push(1);
        }
        
        if (!errors.length) {
            let data = $('#index-newsletter-form').serialize() + '&action=sendContactToAC';

            $.ajax({
                type: 'POST',
				dataType : "json",
				url: ajaxEndpoint,
				data: data,
                success: (response) => {
                    if (response.status) {
                        $('.index-newsletter-form-section').fadeOut(function() {
                            $('.index-newsletter-form-success').fadeIn();
                        });
                        //$('.newsletter-form .form-success-wrapper').addClass('d-block');

                        window.dataLayer = window.dataLayer || []
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                            'obrazec': 'newsletter-prijava'
                        });
                    }
                }
            })
        }
    });

    $('.newsletter-form form').on('submit', (e) => {
        e.preventDefault();

        $('.form-error').removeClass('form-error');

        let errors = [];

        let email = $(this).find('input[name="email"]');
        let terms = $(this).find('input[name="terms2"]');

        if (!validateEmail(email.val())) {
            email.addClass('form-error');

            errors.push(1);
        }

        if (!terms.is(':checked')) {
            terms.addClass('form-error');

            errors.push(1);
        }

        if (!errors.length) {
            console.log('no errors');

            let data = $('.newsletter-form form').serialize() + '&action=salesManagoFooterNewsletter';

            $.ajax({
                type: 'POST',
				dataType : "json",
				url: ajaxEndpoint,
				data: data,
                success: (response) => {
                    if (response.status) {
                        $('.newsletter-form form').addClass('d-none');
                        $('.newsletter-form .form-success-wrapper').addClass('d-block');
                    }
                }
            })
        }
    });

    if (window.matchMedia("(max-width: 992px)")) {
        $('.service-grid-card-title').on('click', function(e) {
            e.preventDefault();

            $(this).parent().toggleClass('open');
        });
    }

    jQuery('.accordion-list .accordion-item .accordion-header').on('click', function() {
        jQuery(this).parent().toggleClass('open');
    });

    $('.video-embed-section .video-wrapper').on('click', function() {
        if (!$(this).hasClass('open')) {
            $(this).addClass('open');

            $(this).find('video').get(0).play();
        }
    });

    $('.video-preview-button').on('click', function() {
        const video = document.querySelector('.embed-video');
        
        if (video.querySelector('source').getAttribute('src') == null) {
            const target = $(this).data('embed-src');
            console.log(video.querySelector('source'));

            video.querySelector('source').setAttribute('src', target);

            video.load();
        } else {   
            video.pause();
            video.currentTime = 0;
            video.load();
        }

        $('.video-popup').fadeIn();

        video.play();
    });

    $('.video-popup .close-button').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        $('.video-popup').fadeOut();
        $('.embed-video-iframe').prop('src', '');
    });

    $('.video-popup .popup-body').on('click', function(e) {
        e.stopPropagation();
    });

    $('.video-popup').on('click', function(e) {
        e.stopPropagation();
        
        $('.video-popup').fadeOut();
        $('.embed-video-iframe').prop('src', '');
    });

    new Swiper('.mellow-candle-slider', {
        slidesPerView: 3.5,
        spaceBetween: 15,
        loop: true,
        allowTouchMove: false,
        speed: 3000,
        autoplay: {
            delay: 1,
        },
        breakpoints: {
            768: {
                slidesPerView: 7,
                spaceBetween: 35,
            }
        }
    });

    new Swiper('.mellow-screen-preview-slider', {
        slidesPerView: 1,
        spaceBetween: 5,
        pagination: {
            el: '.mellow-screen-preview-slider .swiper-pagination',
            clickable: true
        }
    });

    new Swiper('.mobile-preview-slider', {
        slidesPerView: 1,
        spaceBetween: 5,
        pagination: {
            el: '.mobile-preview-slider .swiper-pagination',
            clickable: true
        }
    })
});

$(window).on('load', function() {
    if ($('.achievement-slider').length) {
        gsap.registerPlugin(ScrollTrigger);

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".achievement-slider",
                start: "top bottom",
                end: "bottom top",
                scrub: true,
                markers: false
            }
        });

        const layer = document.querySelector('.achievement-slider');

        tl.to(
            layer, 
            {
                x: (-layer.offsetLeft - layer.offsetWidth), 
                ease: "none"
            }, 
            0
        );
    }
});

/**
 * Get 2 latest blog posts and display in footer
 */
function getPostsFooter() {
	$.ajax({
		dataType: "json",
		url: endpoint + "posts/?per_page=2&_embed",
		success: function(result) {
			let items = [];

			$.each( result, function( key, val ) {
				let cat_name = val._embedded['wp:term'][0][0].name;
				let media_image_url = val._embedded['wp:featuredmedia'][0].media_details.sizes.thumbnail.source_url;
				let date_tmp = new Date(val.date);
				let date = date_tmp.getDate() + "/" + (date_tmp.getMonth() + 1) + "/" + date_tmp.getFullYear();

				items.push( '<div class="link-item"><div class="link-item-img"><a href="' + val.link + '"><div class="blog-img" style="background-image: url(' + media_image_url + ')"><div class="link-item-category">' + cat_name + '</div></div></a></div><div class="link-item-title"><a href="' + val.link + '">' + val.title.rendered + '</a><strong>' +  date + '</strong></div></div>' );
			});

			$( "<div/>", {
				"class": "section-inner-links",
				html: items.join( "" )
			}).appendTo( ".footer-col-2" );
		}
	});
}

/**
 * Get 5 latest blog posts and display in blog section
 */
function getPostsSection() {
	$.ajax({
		dataType: "json",
		url: endpoint + "posts/?per_page=5&_embed",
		success: function(result) {
			let items = [];

            result = result.splice(0, 3)

			$.each( result, function( key, val ) {
				let cat_name = val['_embedded']['wp:term'][0][0].name;
				let media_image_url = val['_embedded']['wp:featuredmedia'][0].media_details.sizes["blog-thumbnail-1x"].source_url;
                
                if (media_image_url === 'undefined') {
                    media_image_url = '';
                }

				//let date_tmp = new Date(val.date);
				//let date = date_tmp.getDate() + "/" + (date_tmp.getMonth() + 1) + "/" + date_tmp.getFullYear();

				//items.push( '<div class="swiper-slide"><div class="blog-item"><a href="' + val.link + '"><div class="blog-content"><div class="blog-date">' +  date + '</div><h3>' + val.title.rendered + '</h3></div><div class="blog-info"><div class="blog-info-inner"><div class="blog-duration"><i class="icon-duration"></i> Branje: <strong>8 min</strong></div></div></div><div class="blog-img swiper-lazy" data-background="' + media_image_url + '"><div class="link-item-category">' + cat_name + '</div></div></a></div></div>' );
                /* items.push( '<div class="swiper-slide"><div class="blog-item"><a href="' + val.link + '"><div class="blog-content"><h3>' + val.title.rendered + '</h3></div><div class="blog-img swiper-lazy" data-background="' + media_image_url + '"></div></a></div></div>' ); */

                items.push(
                    `
                        <a class="rounded-blog-card" href="${ val.link }">
                            <div class="img-wrapper">
                                <img src="${ media_image_url }" alt="${ val.title.rendered }" class="blog-image" loading="lazy">
                            </div>
            
                            <div class="content">
                                <h3 class="blog-card-title">${ val.title.rendered }</h3>
                            </div>
                        </a>
                    `
                );
                
			});

            $('.blog-card-wrapper').html(items.join(''));
		}
	});
}

/**
 * Checks element field is it's empty
 */
function checkField(field) {
    if (field.val() == '') {
        return false;
    }
    return true;
}

/**
 * Checks element value if it's valid email
 */
function validateEmail(email) {
    var re = /\S+@\S+\.\S+/;
    return re.test(email);
}

function validateCompany(field) {
    return (field.val().length > 3);
}

/**
 * On form success, scroll user into view
 */
function myScrollIntoView() {
    jQuery("#scroll-into-view")[0].scrollIntoView({
        behavior: "smooth", // or "auto" or "instant"
        block: "end" // or "end"
    });
}

/*
 * Checks if element is in viewport
 */
function isInViewport(element) {
    var windowX = window.pageYOffset + window.innerHeight;
    var elementX = element.offsetTop + element.offsetHeight / 4;
    return windowX >= elementX;
}

/*
 * Checks if element is in viewport
 */
function isScrolledIntoView() {
    var elements = document.querySelectorAll(".hidden");
    if (elements.length === 0) {
        document.removeEventListener("scroll", isScrolledIntoView);
        return;
    }
    for (var i = 0; i < elements.length; i++) {
        var el = elements[i];
        var inViewport = isInViewport(el);
        if (!inViewport) {
            continue;
        }
        el.classList.remove("hidden");
        el.classList.add(
            "animated",
            el.attributes["data-animation"].nodeValue
        );
    }
}

function scrolledIntoView() {
    if (!$('.esg-landing').length) {
        document.addEventListener("scroll", isScrolledIntoView);
    }
}

function inViewOnLoad() {
    isScrolledIntoView();
    scrolledIntoView();
    window.removeEventListener("load", inViewOnLoad);
}

window.addEventListener("load", inViewOnLoad);

/*
 * Load Google Tag Manager
 */
function loadAnalytics() {
    var gtm = document.createElement('script');
    gtm.innerHTML = "(function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src='https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);})(window,document,'script','dataLayer','GTM-TRGQC8G');";
    document.head.appendChild(gtm);
}

window.addEventListener('load', loadAnalytics);

/*
 * Load Cookie Consent
*/
function loadCookieConsent() {
    let contentData = {
        slo: {
            "message": "Spletna stran za svoje delovanje in spremljanje statistike uporablja spletne piškotke, ki ne hranijo osebnih podatkov.",
            "dismiss": "Zapri",
            "link": "Preberite več",
            "href": "/public/madwise-cookies-sl.pdf"
        },
        en: {
            "message": "We use cookies to improve your experience on our site.",
            "dismiss": "Close",
            "link": "Read more",
            "href": "/public/madwise-cookies-en.pdf"
        }
    };
    


    window.cookieconsent.initialise({
        "palette": {
            "popup": {
                "background": "#5f557d"
            },
            "button": {
                "background": "#ffffff"
            }
        },
        "theme": "classic",
        "content": isEnglishVersion() ? contentData.en : contentData.slo
    });
}

function isEnglishVersion() {
    return window.location.href.includes(`${window.location.host}/en`);
}

function getUrlParameter(param) {
    let searchParams = new URLSearchParams(window.location.search);

    if (!searchParams.has(param)) {
        return null;
    }

    return searchParams.get(param);
}

function getSiteCookieData(callback) {
    let siteSlug = getUrlParameter('site');

    if (window.location.pathname.includes('/piskotki')) {
    if (siteSlug) {
        $.ajax({
            url: `https://madwise.si/blog/wp-json/wp/v2/client_cookie_detail?slug=${siteSlug}`,
            success: function(response) {
                callback(response);
            }
        });
    } else {
        window.location.replace("/");
        //alert('Domena ni bila podana.');
    }
    }

    return [];
}

window.addEventListener('load', loadCookieConsent);

function getCaseStudies(callback) {
    jQuery.ajax({
        url: `https://madwise.si/blog/wp-json/wp/v2/case-studies?_embed=wp:featuredmedia`,
        method: 'GET',
        success: function(response) {
            callback(response);
        }
    });
}

function parseCaseStudyResponse(response) {
    let caseStudies = [];
    
    response.forEach((caseStudy) => {
        let excerpt = caseStudy.excerpt.rendered.replace('<p>', '').replace('</p>', '');

        excerpt = (excerpt.length <= 80) ? excerpt : excerpt.substring(0, 77) + ' ...';

        caseStudies.push({
            title: caseStudy.title.rendered,
            excerpt: excerpt,
            achievementsExcerpt: caseStudy.acf.dosezki_besedilo,
            achievementLink: caseStudy.acf.povezava_do_projekta,
            achievements: caseStudy.acf.dosezki,
            link: caseStudy.link.replace('/blog', ''),
            featuredImageUrl: caseStudy._embedded['wp:featuredmedia'][0].link,
            cardImage: caseStudy.acf.slika_za_kartico.url,
            headerImage: caseStudy.acf.header_slika
        });
    });

    return caseStudies;
}

jQuery(document).ready(function() {
    if (window.location.pathname.includes('/studije-primerov')) {
        getCaseStudies(function(response) {
            let caseStudyObjects = parseCaseStudyResponse(response);
        
            renderCaseStudies(caseStudyObjects);
        });
    }
});

function equalizeBlogCards() {
    let max = 0;
    
    jQuery('.blog-item h3').each(function() {
        if (jQuery(this).height() > max) {
            max = jQuery(this).height();
        }
    });

    jQuery('.blog-item h3').each(function() {
        jQuery(this).height(max);
    });
}

function renderCaseStudyCards(caseStudies) {
    caseStudies.forEach((caseStudy) => {
        let card = document.createElement('div');
        card.classList.add('case-study-card');

        card.innerHTML = `
        <div class="case-study-item">
            <a href="${ caseStudy.link }">
            <div class="card-image-wrapper">
                <img class="card-image swiper-lazy" data-src="${ caseStudy.cardImage }" alt="">
                <div class="case-study-card-overlay">
                    <div class="row d-flex justify-content-center align-items-center h-100">
                        <div class="col-5">
                            <img data-src="${ caseStudy.headerImage.link }" alt="${ caseStudy.headerImage.alt }" class="swiper-lazy" />
                        </div> 
                    </div>
                </div>
            </div>
            <div class="card-content-wrapper">
                <span class="card-tag">Študija Primera</span>
                <h3 class="card-title">${ caseStudy.title }</h3>
                <p class="card-excerpt">${ caseStudy.excerpt }</p>
            </div> 
            </a>
            </div>
        `;

        let swiperSlide = document.createElement('div');
        swiperSlide.classList.add('swiper-slide');
        swiperSlide.appendChild(card);

        jQuery('.case-study-card-slider-wrapper .swiper-wrapper').append(swiperSlide);
    });

    

    new Swiper('.case-study-card-slider-wrapper', {
        slidesPerView: 1,
        loop: true,
        lazy: {
            watchSlidesVisibility: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
        },
        preloadImages: false,
        navigation: {
            nextEl: '.case-study-arrow-next',
            prevEl: '.case-study-arrow-prev',
        },
        breakpoints: {
            478: {
                slidesPerView: 2,
            },
            768: {
                slidesPerView: 3
            }
        },
        nextEl: '.case-study-arrow-next',
        prevEl: '.case-study-arrow-prev'
    });
}

function renderCaseStudies(caseStudies) {
    caseStudies.forEach((caseStudy) => {
        let wrapper = document.createElement('div');
        wrapper.classList.add('row', 'm-0', 'justify-content-center', 'reference-item', 'reference-item-image-left');

        let ul = document.createElement('ul');
        ul.classList.add('reference-info');

        if ((typeof caseStudy.achievementLink) === 'object') {
            ul.innerHTML += `
                <li class="reference-link">
                    <span><i class="icon-browser"></i></span>
                    <p><a href="${ caseStudy.achievementLink.url }" target="${ caseStudy.achievementLink.target }" rel="noopener">${ caseStudy.achievementLink.title }</a>
                    </p>
                </li>
            `;
        }

        caseStudy.achievements.forEach((achievement) => {
            if (achievement.tip_ikone === 'Stevilo') {
                ul.innerHTML += `
                <li>
                    <span>${ achievement.procent }</i></span>
                    <p>${ achievement.besedilo }</p>
                </li>`;
            } else if (achievement.tip_ikone === 'Check ikona') {
                ul.innerHTML += `
                <li>
                    <span><i class="icon-check-outline"></i></span>
                    <p>${ achievement.besedilo }</p>
                </li>
                `;
            }
        });

        let excerptHtml = '';

        if (caseStudy.achievementsExcerpt.length !== 0) {
            excerptHtml = `<p data-aos="fade-right">${ caseStudy.achievementsExcerpt }</p>`;
        }

        wrapper.innerHTML = `
            <div class="col-sm-12 col-lg-6 p-0">
                <div class="reference-thumbnail" data-aos="fade-right">
                    <a href="${ caseStudy.link }">
                        <img src="${ caseStudy.featuredImageUrl }" alt="${ caseStudy.featuredImageUrl }">
                    </a>
                </div>
            </div>
            <div class="col-sm-12 col-lg-6 p-0">
                <div class="reference-content">
                    <h2 data-aos="fade-right">${ caseStudy.title }</h2>
                    ${ excerptHtml }
                    ${ ul.outerHTML }
                    <div class="btn-wrapper" data-aos="fade-right">
                        <a href="${ caseStudy.link }"
                        class="btn btn-primary">VEČ O PROJEKTU</a>
                    </div>
                </div>
            </div>
        `;

        jQuery('.section-case-studies .container-fluid').append(wrapper);
    });
}

//lazy load JS
jQuery(window).on('load', function() {
    let lazyLoaded = false;
    
    jQuery(document).one('click keydown keyup mousemove touchstart mousewheel', function() {
        if (!lazyLoaded) {
            selectorExists('.section-case-studies-front-section', function() {
                getCaseStudies(function(response) {
                    let caseStudyObjects = parseCaseStudyResponse(response);
            
                    renderCaseStudyCards(caseStudyObjects);
                });
            });

            selectorExists('.blog-card-wrapper', function() {
                getPostsSection();
            });
        
            selectorExists('.footer-top', function() {
                getPostsFooter();
            });
        
            selectorExists('.single-post .section-blog', function() {
                loadBlogSlider();
            });

            selectorExists('.article-image-popup', function() {
                initMagnificPopup();
            });
    
            selectorExists('.slider-types', function() {
                let darilaSlider = new Swiper('.slider-types .swiper-container', {
                    slidesPerView: 1,
                    //loop: true,
                    navigation: {
                        nextEl: '.slider-types .slider-buttons .swiper-button-next',
                        prevEl: '.slider-types .slider-buttons .swiper-button-prev',
                    },
                    pagination: {
                        el: '.slider-types .swiper-pagination',
                        clickable: true
                    },
                    centeredSlides: true,
                    centeredSlidesBounds: true,
                    shortSwipes: true,
                    breakpoints: {
                        768: {
                            shortSwipes: false,
                            allowTouchMove: false
                        }
                    }
                });

                darilaSlider.on('slideChangeTransitionEnd', function () {
                    let selectedSlide = jQuery('.slider-types .swiper-slide-active .option-item');

                    let value = selectedSlide.attr('data-value');

                    jQuery('.slider-types').attr('data-selected', value);
                  });
            });

            selectorExists('.inner-reference-slider .swiper-container', function() {
                new Swiper('.inner-reference-slider .swiper-container', {
                    slidesPerView: 1,
                    spaceBetween: 50,
                    pagination: {
                        el: ".inner-reference-slider .swiper-pagination",
                        clickable: true
                    },
                    lazy: {
                        watchSlidesVisibility: true,
                        loadPrevNext: true,
                        loadPrevNextAmount: 5,
                    },
                    breakpoints: {
                        768: {
                            slidesPerView: 2,
                        }
                    }
                });
            });

            selectorExists('.audit.analiza-spletnega-oglasevanja .section-posts', function() {
                jQuery.ajax({
                    dataType: "json",
                    url: ajaxEndpoint,
                    method: 'POST',
                    data: {
                        'categories': [26, 33],
                        'num': 3,
                        'action': 'fetchPostsByCategory'
                    },
                    success: function(response) {
                          response.message.forEach((post) => {
                            jQuery('.audit.analiza-spletnega-oglasevanja .section-posts .blog-slider').append(
                                `   
                                <div class="blog-item">
                                    <a href="${ post.link }" target="_blank">
                                    <div class="blog-content">
                                        <div class="blog-date">${ post.date }</div>
                                        <h3 style="height: 72px;">${ post.title }</h3>
                                    </div>
                                    <div class="blog-info">
                                        <div class="blog-info-inner">
                                            ${ post.duration }
                                        </div>
                                    </div>
                                    <div class="blog-img swiper-lazy swiper-lazy-loaded" style="background-image: url(${ post.image });">
                                        <div class="link-item-category">${ post.category }</div>
                                    </div>
                                    </a>
                                </div>
                             `
                            )
                        });
                    }
                });
            });

            selectorExists('.audit.osnovna-seo-analiza .section-posts', function() {
                jQuery.ajax({
                    dataType: "json",
                    url: ajaxEndpoint,
                    method: 'POST',
                    data: {
                        'categories': [18],
                        'num': 3,
                        'action': 'fetchPostsByCategory'
                    },
                    success: function(response) {
                          response.message.forEach((post) => {
                            jQuery('.audit.osnovna-seo-analiza .section-posts .blog-slider').append(
                                `   
                                <div class="blog-item">
                                    <a href="${ post.link }" target="_blank">
                                    <div class="blog-content">
                                        <div class="blog-date">${ post.date }</div>
                                        <h3 style="height: 72px;">${ post.title }</h3>
                                    </div>
                                    <div class="blog-info">
                                        <div class="blog-info-inner">
                                            ${ post.duration }
                                        </div>
                                    </div>
                                    <div class="blog-img swiper-lazy swiper-lazy-loaded" style="background-image: url(${ post.image });">
                                        <div class="link-item-category">${ post.category }</div>
                                    </div>
                                    </a>
                                </div>
                             `
                            )
                        });
                    }
                });
            });

            selectorExists('.audit.analiza-spletnega-mesta .section-posts', function() {
                jQuery.ajax({
                    dataType: "json",
                    url: ajaxEndpoint,
                    method: 'POST',
                    data: {
                        'categories': [9],
                        'num': 3,
                        'action': 'fetchPostsByCategory'
                    },
                    success: function(response) {
                          response.message.forEach((post) => {
                            jQuery('.audit.analiza-spletnega-mesta .section-posts .blog-slider').append(
                                `   
                                <div class="blog-item">
                                    <a href="${ post.link }" target="_blank">
                                    <div class="blog-content">
                                        <div class="blog-date">${ post.date }</div>
                                        <h3 style="height: 72px;">${ post.title }</h3>
                                    </div>
                                    <div class="blog-info">
                                        <div class="blog-info-inner">
                                            ${ post.duration }
                                        </div>
                                    </div>
                                    <div class="blog-img swiper-lazy swiper-lazy-loaded" style="background-image: url(${ post.image });">
                                        <div class="link-item-category">${ post.category }</div>
                                    </div>
                                    </a>
                                </div>
                             `
                            )
                        });
                    }
                });
            });

            lazyLoaded = true;
        }        
    });
});

function selectorExists(selector, callback) {
    if (jQuery(selector).length) {
        callback();
    }
}

function initMagnificPopup() {
    jQuery('.article-image-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }
    });
}

function loadBlogSlider() {
    let numberOfSlides = jQuery('.blog-item').length;

    let config = {
        slidesPerView: 1,
        lazy: {
            watchSlidesVisibility: true,
            loadPrevNext: true,
            loadPrevNextAmount: 1,
        },
        preloadImages: false,
        allowTouchMove: numberOfSlides > 1,
        navigation: numberOfSlides > 1 ? {
            nextEl: '.single-post .blog-slider .slider-next',
            prevEl: '.single-post .blog-slider .slider-prev'
        } : {},
        loop: (numberOfSlides > 1),
        breakpoints: {
            478: {
                slidesPerView: 2,
                loop: (numberOfSlides > 2),
                allowTouchMove: numberOfSlides > 2,
                navigation: numberOfSlides > 2 ? {
                    nextEl: '.single-post .blog-slider .slider-next',
                    prevEl: '.single-post .blog-slider .slider-prev'
                } : {},
            },
            768: {
                slidesPerView: 3,
                loop: (numberOfSlides > 3),
                allowTouchMove: numberOfSlides > 3,
                navigation: numberOfSlides > 3 ? {
                    nextEl: '.single-post .blog-slider .slider-next',
                    prevEl: '.single-post .blog-slider .slider-prev'
                } : {},
            }
        },
    };

    if (numberOfSlides <= 3) {
        jQuery('.slider-next').addClass('hide-on-swiper-desktop');
        jQuery('.slider-prev').addClass('hide-on-swiper-desktop');
    }

    if (numberOfSlides <= 2) {
        jQuery('.slider-next').addClass('hide-on-swiper-tablet');
        jQuery('.slider-prev').addClass('hide-on-swiper-tablet');
    }

    if (numberOfSlides <= 1) {
        jQuery('.slider-next').addClass('hide-on-swiper-mobile');
        jQuery('.slider-prev').addClass('hide-on-swiper-mobile');
    }

    new Swiper('.single-post .blog-slider .swiper-container', config);
}

/* MW Darile */
jQuery(document).ready(function() {
    jQuery('.btn-option-select').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();

        jQuery('.darila-form #paket').val(jQuery(this).attr('data-value'));
        jQuery('.darila-popup .selected-package').html(jQuery(this).attr('data-value'));
       
        jQuery('.darila-popup .selected-package').removeClass('.selected-package-smelost');
        jQuery('.darila-popup .selected-package').removeClass('.selected-package-ubranost');
        jQuery('.darila-popup .selected-package').removeClass('.selected-package-predanost');

        jQuery('.darila-popup .selected-package').addClass(`selected-package-${jQuery(this).attr('data-value')}`);

        openDarilaPopup();
    });

    jQuery('.btn-option-select-skv').on('click', function(e) {
        e.stopPropagation();
        e.preventDefault();

        jQuery('.darila-form-skv #paket').val(jQuery(this).attr('data-value'));
        jQuery('.darila-popup .selected-package').html(jQuery(this).attr('data-value'));
       
        jQuery('.darila-popup .selected-package').removeClass('.selected-package-smelost');
        jQuery('.darila-popup .selected-package').removeClass('.selected-package-ubranost');
        jQuery('.darila-popup .selected-package').removeClass('.selected-package-predanost');

        jQuery('.darila-popup .selected-package').addClass(`selected-package-${jQuery(this).attr('data-value')}`);

        openDarilaPopupSkv();
    });

    jQuery('.darila-popup .close-btn').on('click', function() {
        closeDarilaPopup();
    });

    jQuery('.darila-popup .form input[type="text"]').on('keyup', function() {
        if (jQuery(this).val().length) {
            jQuery(this).addClass('has-value');
        } else {
            jQuery(this).removeClass('has-value');
        }

        jQuery(this).removeClass('input-invalid');
        jQuery('.darila-form').removeClass('error-form')
    });

    $(document).on('click', '.page.darila a[href^="#"], .page.instant-resitve a[href^="#"]', function (event) {
        event.preventDefault();
        event.stopPropagation();

        $('html, body').animate({
            scrollTop: $($.attr(this, 'href')).offset().top
        }, 100);
    });

    let isDarilaFormLocked = false;

    jQuery('.darila-form').on('submit', function(e) {
        e.preventDefault();

        let errors = validateDarilaForm();

        if (!errors.length) {
            let data = `${jQuery('.darila-form').serialize()}&action=submitMwDarilaForm`;

            if (!isDarilaFormLocked) {
                isDarilaFormLocked = true;

                jQuery.ajax({
                    url: ajaxEndpoint,
                    method: 'POST',
                    data: data,
                    success: function(response) {
                        if (response.status === 'success') {
                            showDarilaThankYouMessage();
                        } else if (response.status === 'error') {
                            jQuery('.form-error-text').html(response.message);
                            jQuery('.darila-form').addClass('error-form');
                        }

                        isDarilaFormLocked = false;
                    }
                });
            }
        }
    });

    jQuery('.darila-form-skv').on('submit', function(e) {
        e.preventDefault();

        let errors = validateDarilaFormSkv();

        if (!errors.length) {
            let data = `${jQuery('.darila-form-skv').serialize()}&action=submitMwDarilaFormSkv`;

            if (!isDarilaFormLocked) {
                isDarilaFormLocked = true;

                jQuery.ajax({
                    url: ajaxEndpoint,
                    method: 'POST',
                    data: data,
                    success: function(response) {
                        if (response.status === 'success') {
                            showDarilaThankYouMessage();
                        } else if (response.status === 'error') {
                            jQuery('.form-error-text').html(response.message);
                            jQuery('.darila-form-skv').addClass('error-form');
                        }

                        isDarilaFormLocked = false;
                    }
                });
            }
        }
    });

    jQuery('.audit .audit-form').on('submit', function(e) {
        e.preventDefault();

        let errors = [];

        jQuery('.form-error').removeClass('form-error');
        jQuery('.form-section  .error-message-wrapper').addClass('d-none');

        let name = jQuery('.audit .audit-form input[name="ime-in-priimek"]');
        let company = jQuery('.audit .audit-form input[name="podjetje"]');
        let email = jQuery('.audit .audit-form input[name="email"]');
        let webpage = jQuery('.audit .audit-form input[name="spletna-stran"]');
        let terms = jQuery('.audit .audit-form input[name="terms"]');

        if (!checkField(name)) {
            name.addClass('form-error');

            errors.push('1');
        }

        if (!checkField(company)) {
            company.addClass('form-error');

            errors.push('1');
        }

        if (!checkField(email) || !validateEmail(email.val())) {
            email.addClass('form-error');

            errors.push('1');
        }

        if (!checkField(webpage)) {
            webpage.addClass('form-error');

            errors.push('1');
        }

        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");

            errors.push("1");
        }

        if (!errors.length) {
            let data = jQuery(this).serialize() + '&action=submitAuditForm2023';

            jQuery.ajax({
                url: ajaxEndpoint,
                data: data,
                method: 'POST',
                success: function(response) {
                    if (response.status === 'success') {
                        jQuery('.audit .audit-form')[0].reset();

                        jQuery('body').addClass('overflow-hidden');
                        jQuery('.audit-submit-popup').fadeIn();

                        window.dataLayer = window.dataLayer || [];

                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });
                    } else {
                        jQuery('.form-section .error-message').text(response.message);
                        jQuery('.form-section .error-message-wrapper').removeClass('d-none');
                    }
                }
            })
        }
    });

    jQuery('.audit-submit-popup .close-btn').on('click', function() {
        jQuery('body').removeClass('overflow-hidden');
        jQuery('.audit-submit-popup').fadeOut();
    });

    jQuery('.question .btn-next').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        let currentQuestion = jQuery(this).closest('.question');
        let currentQuestionId = currentQuestion.attr('data-question-id');

        currentQuestion.addClass('overflow-hidden');

        if (currentQuestionId != 3) {         
            jQuery(`.question[data-question-id="${ +currentQuestionId + 1 }"]`).removeClass('d-none');
        } else {
            let score = 0;

            jQuery('.question input[type="radio"]:checked').each(function() {
                score += +jQuery(this).attr('data-points');
            });

            jQuery('.section-instant-resitve-header').addClass('selection-made');
            jQuery('.upper-header .description').addClass('d-none');
            jQuery('.btn-cta-form-wrapper').removeClass('d-none');

            if (score <= 3) {
                jQuery('.upper-header .title').text('Ups, kmalu vam bo zmanjkalo instant rešitev.');

                jQuery('.result-ljubitelj-instant-resitev').removeClass('d-none');

                jQuery('.form-section #tag').val('INSTANT_INSTANT_KAMPANIA');
            } else if (score <= 7) {
                jQuery('.upper-header .title').text('Super, oceniti znate, kdaj je čas za instant rešitve in kdaj ne.');

                jQuery('.result-zbiratelj-receptov-za-uspeh').removeClass('d-none');

                jQuery('.form-section #tag').val('MEDIUM_INSTANT_KAMPANIA');
            } else {
                jQuery('.upper-header .title').text('Zagovarjate premišljene in dovršene rešitve – ne glede na vse.');

                jQuery('.result-preizkusevalec-najboljskih-receptov').removeClass('d-none');

                jQuery('.form-section #tag').val('PREMIUM_INSTANT_KAMPANIA');
            }

            jQuery('.instant-resitve-results-section').removeClass('d-none');
            jQuery('.instant-resitve-content-section').addClass('d-none');

            jQuery("html, body").animate({ scrollTop: 0 }, "medium");
        }
    });

    jQuery('.question .radio-wrapper input').on('change', function() {
        jQuery(this).closest('.question').find('.btn-next').removeClass('disabled');
    });

    jQuery('#instant-resitve-form').on('submit', function(e) {
        e.preventDefault();

        let name = jQuery('#vase-ime');
        let email = jQuery('#email');
        let company = jQuery('#vase-podjetje');
        let message = jQuery('#izzivi-in-cilji');
        let terms = jQuery('#terms');

        let errors = [];

        jQuery(".form-error").removeClass("form-error");
        
        if (!checkField(name)) {
            name.parent().addClass("form-error");

            errors.push("1");
        }

        if (!checkField(email) || !isEmail(email)) {
            email.parent().addClass("form-error");

            errors.push("1");
        }

        if (!checkField(company)) {
            company.parent().addClass("form-error");

            errors.push("1");
        }

        if (!checkField(message)) {
            message.parent().addClass("form-error");

            errors.push("1");
        }

        if(!terms.is(':checked')) {
            terms.parent().addClass("form-error");

            errors.push("1");
        }

        if (!errors.length) {
            let data = jQuery('#instant-resitve-form').serialize();

            data += '&action=instantResitveFormSubmit';

            jQuery.ajax({
                method: 'POST',
                url: ajaxEndpoint,
                data: data,
                success: function(response) {
                    if (response.status === 'success') {
                        jQuery('.section-instant-resitve-header').addClass('d-none')
                        jQuery('.instant-resitve-results-section').addClass('d-none');

                        jQuery('.instant-thank-you-message').removeClass('d-none');

                        jQuery("html, body").animate({ scrollTop: 0 }, "medium");

                        jQuery('.btn-cta-form-wrapper').addClass('d-none');

                        window.dataLayer = window.dataLayer || []

                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });
                    }
                }
            });
        }
    });

    
    jQuery('.question-accordion .question-content').on('click', function() {
        jQuery(this).siblings('.answer-body').toggleClass('d-none');
        jQuery(this).toggleClass('open');
    });

    jQuery('.konverzijski-landing a').on('click', function(e) {
        if (jQuery(this).attr('href').startsWith('#')) {
            e.stopPropagation();
            e.preventDefault();
         
            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top
            }, 400);
        }
    });

    jQuery('#konverzijski-landing-form input[type="submit"]').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();

        jQuery('.form-error').removeClass('form-error');

        let errors = [];

        let name = jQuery('input[name="ime-in-priimek"]');
        let company = jQuery('input[name="podjetje"]');
        let email = jQuery('input[name="email"]');
        let webpage = jQuery('input[name="spletna-stran"]');

        let consent = jQuery('input[name="terms"]');

        if (!checkField(name)) {
            name.parent().addClass('form-error');
            errors.push(1);
        }

        if (!checkField(company)) {
            company.parent().addClass('form-error');
            errors.push(1);
        }

        if (!checkField(email) || !isEmail(email)) {
            email.parent().addClass('form-error');
            errors.push(1);
        }

        if (!checkField(webpage)) {
            webpage.parent().addClass('form-error');
            errors.push(1);
        }

        if (!consent.is(':checked')) {
            consent.parent().addClass('form-error');
            errors.push(1);
        }

        if (!errors.length) {
            jQuery.ajax({
                url: ajaxEndpoint,
                data: jQuery('#konverzijski-landing-form').serialize() + '&action=submitAnalizaSpletnihStrani',
                method: 'POST',
                success: function(response) {
                    if (response.status === 'success') {
                        jQuery('.konverzija-form-submit-success-popup').removeClass('d-none');
                        jQuery('body').addClass('overflow-hidden');

                        window.dataLayer = window.dataLayer || [];
                        
                        dataLayer.push({
                            'event': 'obrazecIzpolnjen',
                        });
                    }
                }
            });
        }
    });

    jQuery('.konverzija-form-submit-success-popup .close-btn').on('click', function() {
        jQuery('.konverzija-form-submit-success-popup').addClass('d-none');
        jQuery('body').removeClass('overflow-hidden');
    });

    selectorExists('#dop-form', function() {
        jQuery.ajax({
            url: ajaxEndpoint,
            data: {
                action: 'dopGetDays'
            },
            success: function(response) {
                let html = '';

                response.forEach((element) => {
                    html += `<option value="${ element.d_id }">${ element.day }</option>`;
                });

                jQuery('#dan').html(html);

                reloadPeriods();
            }
        });

        jQuery('#dop-form #dan').on('change', function() {
            reloadPeriods();
        });    
    });

    jQuery('#dop-form').on('submit', function(e) {
        e.preventDefault();

        showCampaignOverPopup();
    });

    jQuery('.dov-popup .close-icon').on('click', function() {
        jQuery(this).closest('.dov-popup').addClass('d-none');

        jQuery('body').removeClass('overflow-hidden');
    });

    jQuery('.period-taken-popup .btn-cta').on('click', function() {
        jQuery(this).closest('.dov-popup').addClass('d-none');

        jQuery('body').removeClass('overflow-hidden');
    });

    jQuery('a.btn-theme').on('click', function(e) {
        e.preventDefault();
        e.stopPropagation();
        
        jQuery('#dan option').each(function() {
            jQuery(this).removeAttr('selected');
        });
        
        jQuery(`#dan option[value="${ jQuery(this).attr('data-val') }"]`).attr('selected', true);

        jQuery('#dan').trigger('change');

        jQuery([document.documentElement, document.body]).animate({
            scrollTop: jQuery(".form-section").offset().top
        }, 1000);        
    });

    jQuery(".dnevi-odprtih-vrat a").click(function(e) {
        if (jQuery(this).attr('href').startsWith('#')) {
            e.stopPropagation();
            e.preventDefault();
            
            jQuery([document.documentElement, document.body]).animate({
                scrollTop: jQuery(jQuery(this).attr('href')).offset().top
            }, 1000);
        }
    });
});

$(document).ready(function() {
    let lockScroll = false;
    let isOverScrolllableElement = false;
    let transitioning = false;

    const isMobile = window.matchMedia("(max-width: 992px)").matches;

    let fullPage;

    if (!isMobile && $('#fullpage').length) {
        fullPage = new fullpage('#fullpage', {
            licenseKey: 'R17L9-0F7OK-KUY96-1J1F7-ZQOLO',
            credits: {
                enabled: false
            },
            anchors: ['mainSection'],
            lockAnchors: true,
            scrollHorizontally: true,
            scrollOverflow: true,
            loopHorizontal: false,
            controlArrows: false,
            dragAndMove: false,
            touchSensitivity: 5,
            autoScrolling: false,
            scrollingSpeed: 1000,
            beforeLeave: function() {
                transitioning = true;
            },
            afterRender: function() {
                let slideNum = 0;

                $('#fullpage .slide').each(function() {
                    if ($(this).data('visibility') !== 'hidden') {
                        slideNum++;
                    }
                });

                for (let i = 0; i < slideNum; i++) {
                    let element = document.createElement('div');

                    element.classList.add('pagination-bullet');
                    element.setAttribute('data-index', i);

                    if (i === 0) {
                        element.classList.add('active');
                    }

                    $('.floating-pagination').append(element);
                }

                isScrolledBefore('.planet-slide-outer', function() {
                    $('.earth-image').addClass('fade-in');
                });

                setTimeout(function() {
                    if (window.location.href.includes('#kontakt')) {
                        fullPage.moveTo('mainSection', 4);
                    }

                    if (window.location.href.includes('#reference')) {
                        fullPage.moveTo('mainSection', 3);
                    }
                }, 500);
            },
            onSlideLeave: function(section, origin, destination, direction, trigger) {
                $('.pagination-bullet').removeClass('active');
                $(`.pagination-bullet[data-index="${ destination.index }"]`).addClass('active');
            },
            afterSlideLoad: function(section, origin, destination, direction, trigger) {
                switch (destination.index) {
                    /* case 1:
                        //e slide
                        $('.e-slide .text-1').addClass('fade-in');
                        $('.e-slide .text-2').addClass('fade-in');
                        $('.e-slide .text-3').addClass('fade-in');

                        break;
                    case 2:
                        //s slide
                        $('.s-slide .text-1').addClass('fade-in');
                        $('.s-slide .text-2').addClass('fade-in');
                        $('.s-slide .text-3').addClass('fade-in');

                        break;
                    case 3:
                        //g slide
                        $('.g-slide .text-1').addClass('fade-in');
                        $('.g-slide .text-2').addClass('fade-in');
                        $('.g-slide .text-3').addClass('fade-in');

                        break;
                    case 4:
                        //idealisti slide
                        $('.idealisti-slide .text-4').addClass('fade-in');

                        break; */
                    case 1:
                        //planet slide

                        $('.earth-image').addClass('fade-in');

                        break;
                }

                transitioning = false;
            }
        });

        $('body').on('click', '.pagination-bullet', function() {
            const target = $(this).data('index');

            fullPage.moveTo('mainSection', target);
        });

        let wheelTimeout = null;

        window.addEventListener('wheel', function(event) {
            let element = this.document.querySelector('.slide.active .fp-overflow');

            let isSlideStartReached = $('.slide.active .fp-overflow').scrollLeft() == 0;
            let isSlideEndReached = $('.slide.active .fp-overflow')[0].scrollWidth - $(window).width() == $('.slide.active .fp-overflow').scrollLeft();

            /* if (event.deltaY < 0 && isSlideStartReached && !lockScroll) {
                fullPage.moveSlideLeft();
            } else if (event.deltaY > 0 && isSlideEndReached && !lockScroll) {
                fullPage.moveSlideRight();
            } else {
                event.preventDefault();
                element.scrollLeft += event.deltaY / 2;
            } */

            if (!transitioning) {
                if (event.deltaY < 0 && !lockScroll && !isOverScrolllableElement) {
                    fullPage.moveSlideLeft();
                } else if (event.deltaY > 0 && !lockScroll && !isOverScrolllableElement) {
                    fullPage.moveSlideRight();
                }

                clearInterval(wheelTimeout);

                wheelTimeout = setTimeout(function() {
                    lockScroll = false;
                }, 50);

                if (!lockScroll) {
                    lockScroll = true;
                }
            }
        });

        $(document).on('click', function() {
            $('.esg-landing .menu-content-wrapper').removeClass('open');
            $('.esg-landing .menu-button').removeClass('hidden');
        });

        $('.faq-items').on('mouseenter', function() {
            isOverScrolllableElement = true;
        });

        $('.faq-items').on('mouseleave', function() {
            isOverScrolllableElement = false;
        });

        $('.reference-card').on('mouseenter', function() {
            isOverScrolllableElement = true;
        });

        $('.reference-card').on('mouseleave', function() {
            isOverScrolllableElement = false;
        });

        /* new SimpleBar(document.querySelector('.faq-items-inner'), {}); */

        $('*[data-fp-href]').on('click', function(e) {
            e.preventDefault();
            
            fullPage.moveTo('mainSection', $(this).data('fp-href') - 1);
        });

        let elements = $('.reference-card-outer-text');

        for (let i = 0; i < elements.length; i++) {
            new SimpleBar(elements[i], {
                autoHide: false
            });
        }

        let referenceCardCounter = 0;
        let pageNum = 0;

        $('.reference-esg-content').each(function() {
            $('.reference-esg-content .reference-grid-inner').each(function() {
                $(this).find('.reference-card').each(function() {
                    let cardId = +$(this).data('card-id');

                    if (cardId > pageNum) {
                        pageNum = cardId;
                    }
                });
            });
        });

        let page = 1;

        $('.reference-esg-content .grid-move-next-button').on('click', function() {
            if (page < pageNum) {
                $('.reference-esg-content .reference-grid-inner').each(function() {
                    $(this).find(`.reference-card[data-card-id="${ page }"]`).addClass('reference-card-hidden');    
                });

                page++;

                handleGridMoveButtons(page);
            }
        });

        $('.reference-esg-content .grid-move-prev-button').on('click', function() {
            if (page > 1) {
                $('.reference-esg-content .reference-grid-inner').each(function() {
                    $(this).find(`.reference-card[data-card-id="${ page - 1 }"]`).removeClass('reference-card-hidden');
                });

                page--;

                handleGridMoveButtons(page);
            }
        });

        const handleGridMoveButtons = (page) => {
            if (page === 1) {
                $('.grid-move-prev-button').addClass('grid-move-button-hidden');
            } else {
                $('.grid-move-prev-button').removeClass('grid-move-button-hidden');
            }

            if (page === pageNum - 1) {
                $('.grid-move-next-button').addClass('grid-move-button-hidden');
            } else {
                $('.grid-move-next-button').removeClass('grid-move-button-hidden');
            }
        };
    }

    if (isMobile) {
        $(document).on('click', 'a[href^="#"]', function (event) {
            event.preventDefault();

            $('html, body').animate({
                scrollTop: $($.attr(this, 'href')).offset().top - 50
            }, 400);

            $('.esg-landing .menu-content-wrapper').removeClass('open');
            $('.menu-content-wrapper a').removeClass('hidden');
            $('.menu-button').removeClass('hidden');
        });

        isScrolledBefore('.planet-slide-outer', function() {
            $('.earth-image').addClass('fade-in');
        });
    }

    $('.esg-landing .menu-button').on('click', function(e) {
        e.stopPropagation();

        $('.esg-landing .menu-content-wrapper').addClass('open');
        $(this).addClass('hidden');
    });

    $('.esg-landing .menu-content-wrapper .close').on('click', function(e) {
        e.stopPropagation();

        $('.esg-landing .menu-content-wrapper').removeClass('open');
        $('.esg-landing .menu-button').removeClass('hidden');
    });

    $('.menu-content-wrapper').on('click', function(e) {
        e.stopPropagation();
    });

    $('.fixed-nav-bar').on('click', function(e) {
        e.stopPropagation();
    });

    $('.faq-items .faq-item-title').on('click', function() {
        if (!$(this).parent().hasClass('open')) {
            $('.faq-item.open').removeClass('open');
        }

        $(this).parent().toggleClass('open');
    });

    if (isMobile) {
        $('.esg-landing .reference-card .logo').on('click', function() {
            $(this).parent().toggleClass('open');
        });

        if (window.location.href.includes('#kontakt')) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('div[data-anchor="kontakt"]').offset().top
            }, 1000);
        }

        if (window.location.href.includes('#reference')) {
            $([document.documentElement, document.body]).animate({
                scrollTop: $('div[data-anchor="reference"]').offset().top
            }, 1000);
        }
    }

    const esgSwiper = new Swiper('.swiper-esg-slides', {
        slidesPerView: 1,
        loop: false,
        spaceBetween: 100,
        pagination: {
            clickable: true,
            el: '.swiper-esg-slides .swiper-pagination'
        },
        on: {
            slideChangeTransitionEnd(swiper) {
                switch (swiper.activeIndex) {
                    case 1:
                        $('.s-slide .text-1').addClass('fade-in');
                        $('.s-slide .text-2').addClass('fade-in');
                        $('.s-slide .text-3').addClass('fade-in');

                        break;
                    case 2:
                        $('.g-slide .text-1').addClass('fade-in');
                        $('.g-slide .text-2').addClass('fade-in');
                        $('.g-slide .text-3').addClass('fade-in');

                        break;
                }
            }
        }
    });

    $('.swiper-esg-slides').on('click', function() {
        esgSwiper.slideNext();
    });

    if ($('.esg-landing .blog-links .grid-wrapper').length) {
        $.ajax({
            url: `${ endpoint }posts?categories=38&per_page=3&_embed`,
            method: 'GET',
            success: function(results) {
                let html = '';

                let characterLimit = window.matchMedia("(max-width: 1700px)").matches ? 60 : 100

                results.forEach((el, index) => {

                    if (el.excerpt.rendered.length > characterLimit) {
                        el.excerpt.rendered = el.excerpt.rendered.substring(0, characterLimit) + ' ...';
                    }

                    html += `
                    <a class="blog-card ${ index % 2 == 1 ? 'ml-auto' : 'mr-auto' }" href="${ el.link }">
                        <div class="img-wrapper">
                            <img src="${ el._embedded['wp:featuredmedia'][0]['media_details']['sizes']['medium']['source_url'] }" alt="${ el._embedded['wp:featuredmedia'][0]['alt_text'] }">
                        </div>

                        <div class="content">
                            <h3 class="title">${ el.title.rendered }</h3>

                            ${ el.excerpt.rendered }

                            <div class="link-text-wrapper">
                                <span class="link-text">
                                    Preberi blog
                                    <div class="link-icon">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99902 7L9.99902 9C9.99902 9.26522 9.89367 9.51957 9.70613 9.70711C9.51859 9.89464 9.26424 10 8.99902 10L2.99902 10C2.7338 10 2.47945 9.89464 2.29191 9.70711C2.10438 9.51957 1.99902 9.26522 1.99902 9L1.99902 3C1.99902 2.73478 2.10438 2.48043 2.29191 2.29289C2.47945 2.10535 2.7338 2 2.99902 2L4.99902 2M5.99902 6L9.99902 2M9.99902 2L7.49902 2M9.99902 2L9.99902 4.5" stroke="#271873" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </span>

                                <span class="link-text link-text-hover">
                                    Preberi blog
                                    <div class="link-icon">
                                        <svg width="12" height="12" viewBox="0 0 12 12" fill="none" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M9.99902 7L9.99902 9C9.99902 9.26522 9.89367 9.51957 9.70613 9.70711C9.51859 9.89464 9.26424 10 8.99902 10L2.99902 10C2.7338 10 2.47945 9.89464 2.29191 9.70711C2.10438 9.51957 1.99902 9.26522 1.99902 9L1.99902 3C1.99902 2.73478 2.10438 2.48043 2.29191 2.29289C2.47945 2.10535 2.7338 2 2.99902 2L4.99902 2M5.99902 6L9.99902 2M9.99902 2L7.49902 2M9.99902 2L9.99902 4.5" stroke="#271873" stroke-linecap="round" stroke-linejoin="round"/>
                                        </svg>
                                    </div>
                                </span>
                            </div>
                        </div>
                    </a>
                    `;
                });

                $('.esg-landing .blog-links .grid-wrapper').append(html);
            }
        });
    }

    $('.esg-landing .esg-form input, .esg-landing .esg-form textarea').on('keydown', function() {
        if ($(this).length > 0) {
            $(this).addClass('value-entered');
        } else {
            $(this).removeClass('value-entered');
        }
    });

    let lockForm = false;

    $('.esg-landing .form-slide form').on('submit', function(e) {
        e.stopPropagation();
        e.preventDefault();

        if (!lockForm) {
            $('.error-field').removeClass('error-field');

            let errors = [];

            const fName = $('.esg-landing .form-slide form input[name="fname"]');
            const email = $('.esg-landing .form-slide form input[name="email"]');
            const company = $('.esg-landing .form-slide form input[name="company"]');

            const acceptance = $('.esg-landing .form-slide form input[name="consent"]');

            if (!checkField(fName)) {
                fName.parent().addClass('error-field');

                errors.push(1);
            }

            if (!checkField(email) || !validateEmail(email.val())) {
                email.parent().addClass('error-field');
                
                errors.push(1);
            }

            if (!checkField(company)) {
                company.parent().addClass('error-field');
                
                errors.push(1);
            }

            if (!acceptance.is(':checked')) {
                acceptance.parent().addClass('error-field');
                
                errors.push(1);
            }

            if (!errors.length) {
                let data = $('.esg-landing .form-slide form').serialize()  + '&action=submitESGLandingForm';

                let submitButton = $('.esg-landing .form-slide button[type="submit"]');
                
                submitButton.find('.initial-text').addClass('hide-text');
                submitButton.find('.loading-text').addClass('show-icon');

                lockForm = true;

                $.ajax({
                    url: ajaxEndpoint,
                    method: 'POST',
                    data: data,
                    success: function(response) {
                        if (response.status == 'success') {
                            let successText = $('.esg-landing .form-slide .success-text');
                
                            submitButton.find('.loading-text').removeClass('show-icon');
                            
                            submitButton.find('.finished-text').addClass('show-icon');
                            successText.addClass('visible');

                            $('.value-entered').removeClass('value-entered');
                            $('.esg-landing .form-slide form')[0].reset();

                            dataLayer.push({
                                'event': 'obrazecIzpolnjen',
                            });

                            submitButton.find('.initial-text').addClass('transition-delay');

                            setTimeout(function() {
                                submitButton.find('.finished-text').removeClass('show-icon');
                                successText.removeClass('visible');

                                submitButton.find('.initial-text').removeClass('hide-text');

                                setTimeout(function() {
                                    submitButton.find('.initial-text').removeClass('transition-delay');

                                    lockForm = false;
                                }, 2000);
                            }, 3000);
                        }
                    }
                });
            }
        }
    });

    $('.esg-tabs .tab-item').on('click', function() {
        $('.esg-tabs .tab-item').removeClass('active')

        $(this).addClass('active');

        let that = $(this);

        $.when(
            $('.tab-content-inner.active').fadeOut(300)
        ).done(function() {
            $(`.tab-content-inner[data-target="${ that.data('index') }"]`).fadeIn(300);
            $(`.tab-content-inner[data-target="${ that.data('index') }"]`).addClass('active');            
        });
    });
});

$(window).on('scroll', function() {
    const isMobile = window.matchMedia("(max-width: 992px)").matches;

    if (isMobile) {
        isScrolledInto('.e-slide', function() {
            $('.e-slide .text-1').addClass('fade-in');
            $('.e-slide .text-2').addClass('fade-in');
            $('.e-slide .text-3').addClass('fade-in');
        });

        isScrolledBefore('.planet-slide-outer', function() {
            $('.earth-image').addClass('fade-in');
        });

        isScrolledInto('.idealisti-slide', function() {
            $('.idealisti-slide .text-4').addClass('fade-in');
        });
    }
});

function showSuccessPopup() {
    jQuery('.success-popup').removeClass('d-none');
    jQuery('body').addClass('overflow-hidden');
}

function showPeriodTakenPopup() {
    jQuery('.period-taken-popup').removeClass('d-none');
    jQuery('body').addClass('overflow-hidden');
}

function showEmailUsedPopup() {
    jQuery('.email-used-popup').removeClass('d-none');
    jQuery('body').addClass('overflow-hidden');
}

function showCampaignOverPopup() {
    jQuery('.campaign-over').removeClass('d-none');
    jQuery('body').addClass('overflow-hidden');
}

function reloadPeriods() {
    jQuery.ajax({
        url: ajaxEndpoint,
        data: {
            action: 'dopGetDays'
        },
        success: function(response) {
            let selectedId = jQuery('#dan').val();

            let html = '';

            let targetDay = response.filter((element) => {
                return element.d_id == selectedId;
            });
            
            targetDay[0].periods.forEach((element) => {
                html += `<option value="${ element.t_id }" ${ element.taken == true ? 'disabled' : '' } >${ element.period_from } - ${ element.period_to }</option>`;
            });

            jQuery('#ura').html(html);
            jQuery('#ura').attr('disabled', false);
        }
    });
}

function openDarilaPopup() {
    jQuery('.darila-form')[0].reset();
    jQuery('.darila-form').removeClass('error-form')
    jQuery('.input-invalid').removeClass('input-invalid');
    jQuery('.has-value').removeClass('has-value');

    jQuery('.darila-popup').removeClass('d-none');
    jQuery('body').addClass('overflow-hidden');
}

function openDarilaPopupSkv() {
    jQuery('.darila-form-skv')[0].reset();
    jQuery('.darila-form-skv').removeClass('error-form')
    jQuery('.input-invalid').removeClass('input-invalid');
    jQuery('.has-value').removeClass('has-value');

    jQuery('.darila-popup').removeClass('d-none');
    jQuery('body').addClass('overflow-hidden');
}

function closeDarilaPopup() {
    jQuery('.darila-popup').addClass('d-none');
    jQuery('body').removeClass('overflow-hidden');
}

function validateDarilaForm() {
    jQuery('.error-text').each(function() {
        jQuery(this).html('To polje je obvezno*.');
    });

    jQuery('.input-invalid').removeClass('input-invalid');
    jQuery('.darila-form').removeClass('error-form');

    let ime = jQuery('.darila-form #ime');
    let priimek = jQuery('.darila-form #priimek');
    let email = jQuery('.darila-form #email');
    let ulicaInHistnaStevilka = jQuery('.darila-form #ulica-in-histna-stevilka');
    let postnaStevilka = jQuery('.darila-form #postna-stevilka');
    let kraj = jQuery('.darila-form #kraj');
    let telefonskaStevilka = jQuery('.darila-form #telefonska-stevilka');

    let errors = [];

    if (!hasValue(ime)) {
        ime.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(priimek)) {
        priimek.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(email)) {
        email.addClass('input-invalid');

        errors.push(1);
    } else if (!isEmail(email)) {
        email.siblings('.error-text').html('Polje ni veljavno.');
        email.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(ulicaInHistnaStevilka)) {
        ulicaInHistnaStevilka.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(postnaStevilka)) {
        postnaStevilka.addClass('input-invalid');

        errors.push(1);
    } else if (!isNumber(postnaStevilka)) {
        postnaStevilka.siblings('.error-text').html('Polje ni veljavno.');
        postnaStevilka.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(kraj)) {
        kraj.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(telefonskaStevilka)) {
        telefonskaStevilka.addClass('input-invalid');

        errors.push(1);
    } else if (!isNumber(telefonskaStevilka)) {
        telefonskaStevilka.siblings('.error-text').html('Polje ni veljavno.');
        telefonskaStevilka.addClass('input-invalid');

        errors.push(1);
    }

    return errors;
}

function validateDarilaFormSkv() {
    jQuery('.error-text').each(function() {
        jQuery(this).html('Toto pole je povinné*.');
    });

    jQuery('.input-invalid').removeClass('input-invalid');
    jQuery('.darila-form-skv').removeClass('error-form');

    let ime = jQuery('.darila-form-skv #ime');
    let priimek = jQuery('.darila-form-skv #priimek');
    let email = jQuery('.darila-form-skv #email');
    let ulicaInHistnaStevilka = jQuery('.darila-form-skv #ulica-in-histna-stevilka');
    let postnaStevilka = jQuery('.darila-form-skv #postna-stevilka');
    let kraj = jQuery('.darila-form-skv #kraj');
    let telefonskaStevilka = jQuery('.darila-form-skv #telefonska-stevilka');

    let errors = [];

    if (!hasValue(ime)) {
        ime.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(priimek)) {
        priimek.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(email)) {
        email.addClass('input-invalid');

        errors.push(1);
    } else if (!isEmail(email)) {
        email.siblings('.error-text').html('Pole je neplatné.');
        email.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(ulicaInHistnaStevilka)) {
        ulicaInHistnaStevilka.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(postnaStevilka)) {
        postnaStevilka.addClass('input-invalid');

        errors.push(1);
    } else if (!isNumber(postnaStevilka)) {
        postnaStevilka.siblings('.error-text').html('Pole je neplatné.');
        postnaStevilka.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(kraj)) {
        kraj.addClass('input-invalid');

        errors.push(1);
    }

    if (!hasValue(telefonskaStevilka)) {
        telefonskaStevilka.addClass('input-invalid');

        errors.push(1);
    } else if (!isNumber(telefonskaStevilka)) {
        telefonskaStevilka.siblings('.error-text').html('Pole je neplatné.');
        telefonskaStevilka.addClass('input-invalid');

        errors.push(1);
    }

    return errors;
}

function showDarilaThankYouMessage() {
    jQuery('.darila-popup .form-wrapper').addClass('d-none');
    jQuery('.darila-popup .thank-you-message').removeClass('d-none');
}

function hasValue(field) {
    return !!field.val().length;
}

function isNumber(field) {
    return /[0-9]/.test(field.val());
}

function isEmail(field) {
    return /.*@.*\..*/.test(field.val());
}


jQuery(window).scroll(function() {
    if (isScrolledInto('.swiper-button-prev.animate__animated', function() {
        jQuery('.swiper-button-prev.animate__animated').addClass('animate__slideInLeft');
    }));

    if (isScrolledInto('.swiper-button-next.animate__animated', function() {
        jQuery('.swiper-button-next.animate__animated').addClass('animate__slideInRight');
    }));

    if (window.scrollY > 10) {
        if (isScrolledInto('.audit .scrolling-text', function() {
            jQuery('.audit .scrolling-text').addClass('animate__animated');
            jQuery('.audit .scrolling-text').addClass('animate__slower');

            let isVisible = false;

            jQuery('.audit .scrolling-text').removeClass('animate__slideInLeft');
            jQuery('.audit .scrolling-text').addClass('animate__slideOutRight');

            let state = 1;

            setInterval(() => {
                if (state === 0) {
                    jQuery('.audit .scrolling-text').removeClass('animate__slideInLeft');
                    jQuery('.audit .scrolling-text').addClass('animate__slideOutRight');
                } else if (state === 1) {
                    jQuery('.audit .scrolling-text').removeClass('animate__slideOutRight');
                    jQuery('.audit .scrolling-text').addClass('animate__slideInLeft');

                }

                state++;

                if (state > 2) {
                    state = 0;
                }
                
            }, 3000);
        }));

        if (window.matchMedia("(max-width: 992px)")) {
            if (isScrolledTo('.explanation-section', function() {
                showExplanationCards();            
            }));

            if (isScrolledTo('.audit .description-cards-wrapper', function() {
                showDescriptionCards();
            }));
        } else {
            if (isScrolledBefore('.explanation-section', function() {
                showExplanationCards();            
            }));

            if (isScrolledBefore('.audit .description-cards-wrapper', function() {
                showDescriptionCards();
            }));
        }

        if (isScrolledInto('.audit .promo-banner', function() {
            jQuery('.audit .promo-banner .price').addClass('animate__animated');
            jQuery('.audit .promo-banner .price').addClass('animate__animated');
            jQuery('.audit .promo-banner .price').addClass('wobbleCustom');
        }));
    }
});

function showExplanationCards() {
    let cards = jQuery('.explanation-section .explanation-card');

    setTimeout(() => {
        cards.eq(0).addClass('animate__animated');
        cards.eq(0).addClass('animate__fadeInDown');
    }, 500);

    setTimeout(() => {
        cards.eq(1).addClass('animate__animated');
        cards.eq(1).addClass('animate__fadeInDown');
    }, 1000);
}

function showDescriptionCards() {
    let cards = jQuery('.audit .description-card');

    setTimeout(() => {
        cards.eq(0).addClass('animate__animated');
        cards.eq(0).addClass('animate__fadeInDown');
    }, 500);

    setTimeout(() => {
        cards.eq(1).addClass('animate__animated');
        cards.eq(1).addClass('animate__fadeInDown');
    }, 1000);

    setTimeout(() => {
        cards.eq(2).addClass('animate__animated');
        cards.eq(2).addClass('animate__fadeInDown');
    }, 1500);

    setTimeout(() => {
        cards.eq(3).addClass('animate__animated');
        cards.eq(3).addClass('animate__fadeInDown');
    }, 2000);
}

let bannerBreakpoint = null;

jQuery(window).on('load', function() {
    
    jQuery(".preload").removeClass("preload");

    if (jQuery('.konverzijski-landing').length) {
        jQuery(window).on('scroll', function() {
            if (bannerBreakpoint === null) {
                bannerBreakpoint = jQuery('.konverzijski-landing .banner-cta-section').offset().top + jQuery('.konverzijski-landing .banner-cta-section').outerHeight();
            }
        
            if (
                bannerBreakpoint !== null &&
                jQuery(window).scrollTop() >= bannerBreakpoint
            ) {
                jQuery('.banner-cta-section').addClass('sticky-banner');  
            } else if (
                bannerBreakpoint !== null &&
                jQuery(window).scrollTop() < bannerBreakpoint
            ) {
                jQuery('.banner-cta-section').removeClass('sticky-banner');
            }
        });
    }
});




let loadedSections = [];

function isScrolledInto(selector, callback) {
    if (jQuery(selector).length) {
        if (loadedSections.indexOf(selector) === -1) {
            let hT = jQuery(selector).offset().top;
            let hH = jQuery(selector).outerHeight();
            let wH = jQuery(window).height();
            let wS = jQuery(window).scrollTop();
            
            if (wS > (hT + hH - wH)){
                loadedSections.push(selector);
        
                callback();
            }
        }
    }
}

function isScrolledBefore(selector, callback) {
    if (jQuery(selector).length) {
        if (loadedSections.indexOf(selector) === -1) {
            let hT = jQuery(selector).offset().top;
            let hH = jQuery(selector).outerHeight();
            let wH = jQuery(window).height();
            let wS = jQuery(window).scrollTop();
            
            if (wS > (hT + (hH / 2) - wH)){
                loadedSections.push(selector);
        
                callback();
            }
        }
    }
}

function isScrolledTo(selector, callback) {
    if (jQuery(selector).length) {
        if (loadedSections.indexOf(selector) === -1) {
            let hT = jQuery(selector).offset().top;
            let wH = jQuery(window).height();
            let wS = jQuery(window).scrollTop();
            
            if (wS > (hT + 100 - wH)){
                loadedSections.push(selector);
        
                callback();
            }
        }
    }
}

jQuery(document).ready(function() {
    if (jQuery('.ecommerce-landing').length) {
        gsap.registerPlugin(ScrollTrigger);

        jQuery('.ecommerce-landing-contact-form-section form').on('submit', function(e) {
            e.preventDefault();

            jQuery('.form-error').removeClass('form-error');

            let name = jQuery(this).find('input[name="ime-in-priimek"]');
            let email = jQuery(this).find('input[name="email"]');
            let company = jQuery(this).find('input[name="podjetje"]');
            let message = jQuery(this).find('textarea[name="sporocilo"]');

            let terms = jQuery(this).find('#terms');

            let errors = [];

            if (!checkField(name)) {
                name.parent().addClass('form-error');
                errors.push(1);
            }

            if (!checkField(email) || !isEmail(email)) {
                email.parent().addClass('form-error');
                errors.push(1);
            }

            if (!checkField(company)) {
                company.parent().addClass('form-error');
                errors.push(1);
            }

            if (!checkField(message)) {
                message.parent().addClass('form-error');
                errors.push(1);
            }

            if (!terms.is(':checked')) {
                terms.parent().parent().addClass('form-error');
                errors.push(1);
            }

            if (!errors.length) {
                let data = jQuery(this).serialize() + '&action=submitEcommerceLandingForm';

                jQuery.ajax({
                    url: ajaxEndpoint,
                    data: data,
                    method: 'POST',
                    success: function(response) {
                        if (response.status === 'success') {
                            jQuery('.ecommerce-landing-contact-form-section form')[0].reset();
                
                            jQuery('.form-submit-popup').fadeIn();
                            jQuery('body').addClass('overflow-hidden');

                            window.dataLayer = window.dataLayer || [];

                            dataLayer.push({
                                'event': 'obrazecIzpolnjen',
                            });
                        } else {
                            jQuery('.ecommerce-landing-contact-form-section .error-message').text(response.message);
                            jQuery('.ecommerce-landing-contact-form-section .error-message-wrapper').removeClass('d-none');
                        }
                    }
                })         
            }
        });

        new Swiper('.ecommerce-landing-case-studies .case-study-card-slider-wrapper', {
            slidesPerView: 1,
            loop: true,
            lazy: {
                watchSlidesVisibility: true,
                loadPrevNext: true,
                loadPrevNextAmount: 1,
            },
            preloadImages: false,
            navigation: {
                nextEl: '.case-study-arrow-next',
                prevEl: '.case-study-arrow-prev',
            },
            breakpoints: {
                478: {
                    slidesPerView: 2,
                },
                768: {
                    slidesPerView: 3,
                    allowTouchMove: false
                }
            },
            nextEl: '.case-study-arrow-next',
            prevEl: '.case-study-arrow-prev'
        });

        const tlHeader = gsap.timeline({
            scrollTrigger: {
                trigger: ".commerce-landing-header",
                start: "top top",
                end: "bottom top",
                scrub: true
            }
        });

        gsap.utils.toArray(".parallax-header-rocket").forEach(layer => {
            const depth = layer.dataset.depth;
            const movement = -(layer.offsetHeight * depth)
            tlHeader.to(
                layer, {
                    y: movement, 
                    ease: "none"
                }, 
                0
            )
        });

        const tl = gsap.timeline({
            scrollTrigger: {
                trigger: ".ecommerce-landing-contact-form-section",
                start: "-500px",
                end: "bottom top",
                scrub: true
            }
        });

        if (window.matchMedia("(min-width: 768px)").matches) {
            gsap.utils.toArray(".parallax").forEach(layer => {
                const depth = layer.dataset.depth;
                const movement = (layer.offsetHeight * depth)
                
                tl.to(
                    layer, 
                    {
                        y: movement, 
                        ease: "none"
                    },
                0)
            });
        }

        gsap.utils.toArray(".parallax-rocket").forEach(layer => {
            const depth = layer.dataset.depth;
            const movement = -(layer.offsetHeight * depth)
            tl.to(
                layer, 
                {
                    y: -layer.offsetTop - layer.offsetHeight, 
                    ease: "none"
                }, 
                0
            )
        }); 
    }  
});